import React from "react";
import {
    Page,
    Seo,
} from "gatsby-theme-portfolio-minimal";

export default function IndexPage() {
    return (
        <>
            <Seo title="404 - File Not Found" />
            <Page>
                File not found
            </Page>
        </>
    );
}
